import React from 'react'
import { Profile } from '../../components/pages/profile'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'

const EnglishProfilePage = (props: any) => {
  const siteMetadata = useSiteMetadata()
  const en = props.location.pathname.split('/')[1] === 'en'

  return <Profile {...props} en={en} siteMetadata={siteMetadata} />
}

export default EnglishProfilePage
